import axios from 'axios';
// let SERVER_URL = 'https://test'

type stampParams ={
    s :string,
    p :string,
    c :string,
    eq :string,
}

export type userInfo = {
    key : string
    id : string
    status? : string
    name? : string
    email? : string
    ctn? : string
    os? : string
    equip? : string
    pushid? : string
    gen? : string
    birth? : string
    type ? : string
    
}

export const commonApi = ( SERVER_URL:string ) =>{
    
    return {
        getStamp : async(data : any )=>{
            const response = await axios.post(
                `${SERVER_URL}/cms/stamps/auth/lite`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        updateInfo : async(data : userInfo , client_id:string )=>{
            console.log(data)
            // let client_id = "691764e8-0790-44c3-9842-130fa8288429:35636263313933312D623339632D346531332D626338352D646564343232653939323331"
            const response = await axios.put(
                `${SERVER_URL}/csc/customers`,
                {...data , lang:'en'},
                {
                    headers: {
                      Authorization: 'Basic ' + btoa(client_id)
                    }
                }
            );
            return response.data.result
        },
        
    }
} 
