import { Store } from 'use-global-hook'
import produce from "immer";
import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData, ConfigType } from './echoss';
import Axios from 'axios';
import { getBaseURI } from 'utils/misc';
import { useNavigate } from '@reach/router';
import swal from 'sweetalert';
import HTMLReactParser from 'html-react-parser';

declare var Stripe: any;

export type itemType =  { 
    goods_code : string, 
    amount : string, 
    quantity : number,
    name :string 
}
export interface PaymentAction {
    init:(payload: ConfigType)=>void
    redirectToCheckout:(payload: itemType[] ) =>void
    webhook:(payload:any) =>void
}

export type paymentType = stripeAPI | senangpayAPI | null


const init = (
    store: Store<EchossState, EchossAction>,
    payload : ConfigType ) => {
        store.setState( produce(store.state, (draft)=>{
            if ( payload.payment ) {
                if (payload.payment.stripe_key)
                    draft.payment = new stripeAPI(payload.payment.stripe_key , payload.payment.checkout_url , payload.payment.webhookUrl!)
                if (payload.payment.senangpayUrl) 
                    draft.payment = new senangpayAPI ( payload.payment.senangpayUrl, payload.payment.checkout_url, payload.payment.webhookUrl! )
            }
            
        }))
    }

const redirectToCheckout=  async (
    store:Store<EchossState, EchossAction>,
    payload : itemType[] ) => {
        if(store.state.payment) {
            let loginBy = store.state.loginBy 
            let uri = document.baseURI
            // if (uri[-1] !== "/" ) uri += "/"
            try {
                let result = await store.state.payment.redirectToCheckout({
                    items : payload,
                    cid : store.state.customerID,
                    channel : store.state.voucherState.channel,
                    // success_url : "https://web-echoss.zvolution.com.my/success?login=fb",
                    // cancel_url : "https://web-echoss.zvolution.com.my/cancel?login=fb",
                    // email : store.state.userInfo!.email
                    
                    success_url : `${uri}voucher/success?login=${loginBy}&session_id={CHECKOUT_SESSION_ID}`,
                    cancel_url : `${uri}voucher/cancel?login=${loginBy}`,
                    email :store.state.userInfo!.email,
                    phone : store.state.userInfo!.ctn,
                    name : store.state.userInfo!.name

                })
            }catch(error) {
                swal({
                    icon : "error",
                    content : { 
                        element : store.state.config!.payment!.err_msg || "Transaction went wrong. Please check with admin", //error,
                    },
                    buttons:[false]
                })
            }


        }
    }

const webhook =  async (
    store:Store<EchossState, EchossAction>,
    payload : any ) => {
        try {
            if (store.state.payment) {
                let res = await store.state.payment.webhook(payload)
                swal({
                    title: "Payment Success!!",
                    icon: "success",
                })
            }
        }catch (err) {
            let params = new URLSearchParams(payload)

            let msg = err.response.data.resMsg
            // if (err.response.data.resCd in ["G217"])
            let id = params.get("session_id") || params.get("transaction_id")
            msg = `Something went wrong. Please contact customer support about your transactions ${id}`

            swal({
                // title: msg ,
                text:msg,
                icon: "error"
            })
            console.log(err)
            console.log(err.response)
            console.log(err.toJSON())
        }

        store.actions.voucher.updateIssues()

    }
    


class stripeAPI {
    stripe : any 
    checkout_url : string
    webhookUrl :string
    constructor( pk_secret:string , checkout_url:string, webhookUrl:string) {
       this.stripe = Stripe(pk_secret) 
       this.checkout_url = checkout_url
       this.webhookUrl = webhookUrl 
    }
    async redirectToCheckout (data : any) {

        let response = await Axios.post( this.checkout_url ,{
           ...data 
        })
        console.log(response)
        let sessionId = response.data.id

        this.stripe!.redirectToCheckout({
            sessionId: sessionId
        }).then(function (result:any) {
            
            throw (`Something went wrong.\n ${result.error.network}`)
        });
    }
    async webhook ( payload: any) {
        // let params = new URLSearchParams(payload)
        // let response = await Axios.post (`${this.webhookUrl}` , params )
        // return response
        return null
    }
}


class senangpayAPI {
    senangpayUrl : string
    checkout_url : string
    webhookUrl :string
    name? : string
    email? : string
    phone? : string
    constructor( senangpayUrl :string , checkout_url:string, webhookUrl:string) {
       this.senangpayUrl = senangpayUrl 
       this.webhookUrl = webhookUrl
       this.checkout_url = checkout_url 
    }
    async redirectToCheckout (data : any) {
        // let navigate = useNavigate()

        let response = await Axios.post( this.checkout_url ,{
           ...data ,
        })
        console.log(response)
        let param = {
            detail : response.data.detail ,
            amount : response.data.amount ,
            order_id : response.data.order_id ,
            hash : response.data.hash ,
            name : data.name,
            email : data.email,
            phone : data.phone
        }
        let qs = new URLSearchParams(param)
        let turl = `${this.senangpayUrl}/${response.data.mid}?${qs.toString()}`
        window.location.href = turl
        
    }
    async webhook ( payload: any) {
        let params = new URLSearchParams(payload)
        let response = await Axios.post (`${this.webhookUrl}` , params )
        return response
    }
}

export default {
    actions : {
        init,
        redirectToCheckout,
        webhook
    }
}