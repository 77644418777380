import axios from 'axios';
// let SERVER_URL = 'https://test'

export enum ptypeType {
    goods = 'goods',
    book ='books'
}

export type ProductListParamsType ={
    channel : string,
    query_type : string, 
    brand_id? : string,
    sort_type : string   
}

export type BookListParamsType = {
    channel : string,
    sort_type : string
}

export type ProductDetailParamsType={
    channel : string,
    goods_code : string
}

export type CustomerVoucherParamsType = {
    channel : string,
    cid : string,
    sort_type? : string
}
export type CustomerTrxHistoryParamsType ={
    channel : string,
    order_no? : string,
    ticket_no? : string,
    ext_id? : string // External sales channel ID eg FB ID
}

export type VoucherDetailParamsType = {
    channel : string,
    ticket_no : string
}

export type StampParamsType ={
    eq : string,
    s : string,
    p : string,
    c : string,
    version : string
}
export type OTPParamsType = {
    auth_div_cd : 2,
    stamp_no : string
}

export type StampIssueParamsType = {
    channel : string,
    cid : string,
    trx_no : string,
    brand_id?: string,
    goods_code : string,
    buyer_name? : string,
    recv_info? : string,
    // auth_div_cd : 1 | 2
} & ( ({auth_div_cd : 1, sc_key:string }  & StampParamsType) )


export type MultiUseParamsType = {
    channel : string,
    ticket_type : 1 | 2,
    cid? : string,
    brand_id : string,
    mcnt_id? : string,
    stamp_no?: string,
    
    // auth_div_cd : number,
    auth_div_cd : 1  | 2,
    tickets : {
        ticket_no: string,
    } []
}

export type StampUseParamsType = {
    channel : string,
    ticket_type : 1 | 2,
    ticket_no: string,
    cid? : string,
    goods_div_cd : 1  | 2,
    brand_id : string,
    use_price? : string,
    aprv_no? : string,
    sc_key? : string
} & StampParamsType


export type goodsType = {
    address: string
    brand_id: string
    brand_name : string,
    category : string,
    category_name : string,
    dc_rate : string,
    description : string,
    description_2 : string,
    exp_dt : string,
    expire_date : string,
    expire_div_code : string,
    goods_code : string,
    goods_kind_cd : string,
    goods_name : string,
    goods_use_code : string,
    image : string,
    orgn_goods_code : string,
    price : string,
    sale_en_dt : string,
    sale_price : string,
    sale_st_dt : string,
    supy_price : string,
    tel : string,
    unservice : string,
    usage : string,
    goods_tot_cnt? : string,
    issue_cnt? : string
}

export type booksType = {
    books_code : string,
    books_name : string,
    category : string,
    category_name : string,
    dc_rate : string,
    description : string,
    goods_cnt : string,
    image : string,
    sale_en_dt : string,
    sale_price : string,
    sale_st_dt : string
}

export type ticketType = {
    expire_date : string,
    issue_date : string,
    issue_time : string,
    pin_no : string,
    stat_cd : string,
    ticket_no : string,
    goods_name? : string,
    image? : string,

}

export type issueType = {
    brand_id : string,
    brand_name : string,
    cid : string,
    expire_date : string,
    ext_stg_yn : string,
    goods_code : string,
    goods_name : string,
    goods_seq_no? : string,
    image : string,
    issue_date : string,
    issue_time : string,
    order_no : string,
    price : string,
    ticket_cnt : string,
    tickets : ticketType[]
}

export type ticketIssueType = {
    address : string,
    brand_id : string,
    brand_name : string,
    category : string,
    category_name : string,
    expire_date : string,
    ext_issue_no : string,
    goods_code : string,
    goods_kind_cd : string,
    goods_name : string,
    goods_use_cd : string,
    image : string,
    issue_date : string,
    issue_time : string,
    price : string,
    tel : string,
    ticket_no : string,
}

export type issueDetailType = {
    books_code? : string;
    auth_no?:string,
    etc_info?:string,
    address : string,
    brand_category : string,
    brand_category_name : string,
    brand_id : string,
    brand_name : string,
    category : string,
    category_name : string,
    dc_rate : string,
    description : string,
    description_2 : string,
    expire_date : string,
    ext_issue_no : string,
    goods_code : string,
    goods_kind_cd : string,
    goods_name : string,
    goods_use_cd : string,
    image : string,
    image_detail : string,
    issue_date : string,
    issue_time : string,
    orgn_goods_cd : string,
    price : string,
    stat_cd : string,
    tel : string,
    ticket_no : string,
    unservice : string,
    usage : string,
    use_date : string,
    use_time : string,
}

export type goodsDetailType = {
    address : string,
    brand_id : string,
    brand_name : string,
    description : string,
    dtl_image : string,
    exp_dt : string,
    expire_date : string,
    expire_div_code : string,
    goods_code : string,
    goods_kind_cd : string,
    goods_name : string,
    goods_use_code : string,
    image : string,
    options : any[],
    orgn_goods_code : string,
    price : string,
    supy_price : string,
    tel : string,
    unservice : string,
    usage : string,

    dc_rate : string,
    description_2?: string
    // goods_kind_cd?: string,
    pin_div_code?: string,
    sale_price?: string,
    sale_st_dt?: string

}

export type booksDetailType ={
    image: string,
    books_code : string,
    books_image : string,
    books_name : string,
    category : string,
    category_name : string,
    dc_rate : string,
    desc_images : string[],
    description : string,
    goods : goodsDetailType[],
    goods_cnt : string,
    sale_en_dt : string,
    sale_price : string,
    sale_st_dt : string
}


export const vapi = ( ptype :string , SERVER_URL:string ) =>{
    
    return {
        getProductsList : async(data : ProductListParamsType | BookListParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/list`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        getProductDetails : async(data: ProductDetailParamsType)=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/detail`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        getCustomerVoucher : async(data : CustomerVoucherParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/issue/list`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        getCustomerTrxHistory : async(data : CustomerTrxHistoryParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/issue/trxList`,
                {...data , lang:'en'}
            );
            if(!response.data.result) return {issues : []}
            return response.data.result
        },
        getVoucherDetail : async(data : VoucherDetailParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/issue/detail`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        order : async(data :any)=>{
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/order`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        stampIssue : async(data : StampIssueParamsType  )=>{
            console.log(data)
            const response = await axios.post(
                `${SERVER_URL}/${ptype}/auth/sendCoupon`,
                {...data , lang:'en'}
            );
            console.log(response.data)
            return response.data
        },
        stampUse : async(data : StampUseParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/goods/issue/use`,
                {...data, lang:'en'}
            );
            return response.data
        },
        MultiUse : async(data : MultiUseParamsType )=>{
            const response = await axios.post(
                `${SERVER_URL}/goods/multi/use`,
                {...data, lang:'en'}
            );
            return response.data
        }
    }
} 

export function getCurrentDate() {
	var now = new Date();
	var yyyy = '' + now.getFullYear();
	var mm = (now.getMonth() + 1) > 9 ? '' + (now.getMonth() + 1) : '0' + (now.getMonth() + 1);
	var dd = now.getDate() > 9 ? '' + now.getDate() : '0' + now.getDate();
	return yyyy + mm + dd;
}
