//@ts-ignore
import produce from "immer";
import { Store } from 'use-global-hook'
import {EchossState, EchossAction, EchossMode} from './echoss'
import {vapi, issueType, goodsType, booksType, ptypeType,  booksDetailType, issueDetailType, goodsDetailType} from 'services/voucher'
declare var echoss: any;

// export const productAPI = vapi('goods', 'https://dev-cpapi-echoss.zvolution.com.my/voucher')
// export const bookAPI = vapi('books', 'https://dev-cpapi-echoss.zvolution.com.my/voucher')

// export const productAPI = vapi('goods', 'https://api.zvolution.com.my/dev/echoss')
// export const bookAPI = vapi('books', 'https://api.zvolution.com.my/dev/echoss')
const getServiceDummy = (false as true) && vapi("", "");    //get an "instance" of the function

export interface VoucherState {
    goodsIssues : issueType[],
    booksIssues : issueType[],
    goodsList : goodsType[],
    booksList : booksType[],
    channel :string,
    vdetail? : vDetailType
    idetail? : iDetailType,
    bookAPI? : typeof getServiceDummy,
    productAPI? : typeof getServiceDummy 
}
export type vDetailType = {
    name : string,
    goods_code : string,
    
    price : string,
    desc : string,
    desc2? : string,
    
    sale_price :string,
    image : string,
    dc_rate? : string,
    brand_id?: string,
    brand_name? : string,
    usage? : string,
    unservice? : string,
    sale_st_dt? :string,
    sale_en_dt ? :string,
    goods? : goodsDetailType [],
    exp_dt? : string,
    expire_date? : string
}



export type iDetailType = issueDetailType & { used? : boolean }

export interface VoucherAction {
    init: (payload: string) => void
    setVoucher: (payload: string) => void
    setVoucherCoupon:(payload?:any)=>void
    setVoucherIssue:(payload?:any)=>void
    updateIssues:(payload?:string)=>void
}

export type voucherUrlStateType ={
    ptype : string,
    id : string
}

export const VoucherInitialState = {
    goodsIssues : [],
    booksIssues : [],
    goodsList : [],
    booksList : [],
    channel : ""
}

const updateIssues = async (
    store:Store<EchossState, EchossAction>, 
    payload?:string) => {
        let goodsIssueParams = {
            channel: store.state.voucherState.channel,
            // ext_id: "8b1d2adc-e154-4f42-aa7b-ae20c92e6580"
            ext_id: String(store.state.customerID)
            // goods_code: undefined
            // order_no: undefined,
            // goods_seq_no
        }
        let goodsIssues =  store.state.voucherState.productAPI!.getCustomerTrxHistory(goodsIssueParams)
        let booksIssues =  store.state.voucherState.bookAPI!.getCustomerTrxHistory(goodsIssueParams)
        // store.state.voucherState.bookAPI.getCustomerTrxHistory()
        let [a,b] = await Promise.all([goodsIssues,booksIssues])
        // store.actions        
        store.setState( produce(store.state, (draft)=>{
            draft.voucherState.goodsIssues = a.issues
            draft.voucherState.booksIssues = b.issues
        }))
    }
const setVoucher= async (
    store:Store<EchossState, EchossAction>, 
    payload:string) => {
        echoss.Icon.hideIcon()
        console.log("enter set voucer")
        store.setState( produce(store.state, (draft)=>{
            draft.mode = EchossMode.Voucher
        }))

        store.actions.voucher.updateIssues()
        // Get Product/ Book List
        // Get Ticket/Coupon/Voucher
        let params = {
            channel: store.state.voucherState.channel,
            query_type: "1",
            sort_type: "2"
        }
        let booksparams = {
            channel: store.state.voucherState.channel,            
            sort_type: "2"
        }
        
        let goodsList =  store.state.voucherState.productAPI!.getProductsList(params)
        let booksList =  store.state.voucherState.bookAPI!.getProductsList(booksparams)
        let [a,b] = await Promise.all([goodsList, booksList])
        // store.actions        
        store.setState( produce(store.state, (draft)=>{
            draft.mode = EchossMode.Voucher

            draft.voucherState.goodsList = a.goods
            draft.voucherState.booksList = b.books
            draft.voucherState.vdetail = undefined
            draft.voucherState.idetail = undefined
        }))
    }

const init = async(
    store:Store<EchossState, EchossAction>, 
    payload: any) => {
        
        store.setState(produce(store.state, (draft)=>{
            // draft.coupon = payload
            draft.voucherState.channel = store.state.config!.VOUCHER_CHANNEL!
            draft.voucherState.bookAPI = vapi( "books", store.state.config!.Voucher!.api_url)
            draft.voucherState.productAPI = vapi( "goods", store.state.config!.Voucher!.api_url)
        }))
    }
            
const setVoucherCoupon = async(
    store:Store<EchossState, EchossAction>, 
    payload: any) => {
        let issueDetail: issueDetailType;
        if (payload.ptype === 'goods' ) {
            issueDetail = await store.state.voucherState.productAPI!.getVoucherDetail({
                channel: store.state.voucherState.channel,
                ticket_no: payload.id,
            })
            
        }else {
            issueDetail = await store.state.voucherState.bookAPI!.getVoucherDetail({
                channel: store.state.voucherState.channel,
                ticket_no: payload.id,
            })
        }
                
        //  Check for OTP CONFIG first if used is not set
        if ( parseInt(issueDetail.stat_cd) < 2 ) {
            echoss.Icon.showIcon()
            // echoss.Icon.setEchossIconData({
            //    aprvData    : "auth", //   <- you don't have to set aprvData, but since you cannot leave it in blank, you put "auth" for that space
            //    funcCd      : 'XAUTH',// <- the code of verifying otp in marketing pf
            //    isuDivCd    : echoss.Icon.OTP_ISSUE_TYPE.COMMON_ETC,
            //    cntYn       : "N"
            // }, (result:any) =>{
            //     console.log(result)
            //     console.log(result.mcntId)
            // }, store.actions.onError);


          echoss.Icon.setOtpData({
                aprvData    : "auth",   // <- you don't have to set aprvData, but since you cannot leave it in blank, you put "auth" for that space
                funcCd      : 'XAUTH', // <- the code of verifying otp in marketing pf
                isuDivCd    : echoss.Icon.OTP_ISSUE_TYPE.COMMON_ETC,
                cntYn       : "N"
            }, (result:any)=>{
                console.log(result)
                console.log('done otp');
                // use(result.mcntId)
                store.state.voucherState.productAPI!.MultiUse({
                    channel : store.state.voucherState.channel,
                    // cid : store.state.customerID!, 
                    auth_div_cd : 1,
                    brand_id : store.state.voucherState.idetail!.brand_id,

                    ticket_type : store.state.voucherState.idetail!.books_code ? 2: 1,
                    tickets: [ { ticket_no : store.state.voucherState.idetail!.ticket_no} ],
                }).then(()=>{
                    store.setState ( produce (store.state, (draft)=>{
                        draft.mode = EchossMode.StampDisabled
                        draft.voucherState.idetail!.used = true
                        draft.stampInProgress = false
                    }))
                    
                    store.actions.voucher.updateIssues()
                }) 
            }, store.actions.onError );
        }
        store.setState(produce(store.state, (draft)=>{
            // draft.coupon = payload
            draft.voucherState.idetail = issueDetail

            if (parseInt(issueDetail.stat_cd) > 1 ) {
                draft.voucherState.idetail.used = true
                draft.mode = EchossMode.StampDisabled
            }else {
                draft.mode = EchossMode.VoucherCoupon
                draft.voucherState.idetail.used = false 
            }
        }))
    }

const setVoucherIssue = async (
    store:Store<EchossState, EchossAction>, 
    payload: any) => {

        //  Check for OTP CONFIG first

        // echoss.Icon.showIcon()
        // echoss.Icon.setOtpData({
        //     aprvData 	: set_aprvData(OTP_DIV_CD_USE_COUPON, CUST_ID, FUNC_KEY, payload.issueNo),
        //     funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_ROULETTE,
        //     isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.USE_COUPON,
        //     cntYn 		: "N",
        // }, ()=>{
        //     console.log('done otp');
            
        // }, store.actions.onError
        // )

        // Get Voucher Detail?
        // let vdetail: goodsDetailType|booksDetailType| undefined

        let detail : vDetailType
        if (payload.ptype === ptypeType.goods){
            let vdetail = await store.state.voucherState.productAPI!.getProductDetails({
                channel: store.state.voucherState.channel,
                goods_code : payload.id
            })

            detail ={
                name : vdetail.goods_name,
                goods_code : vdetail.goods_code,
                brand_id : vdetail.brand_id,
                image : vdetail.image,
                sale_price : vdetail.sale_price ? parseFloat(vdetail.sale_price).toFixed(2) : parseFloat(vdetail.price).toFixed(2),
                price : parseFloat(vdetail.price).toFixed(2),
                desc : vdetail.description,
                desc2 : vdetail.description_2,
                sale_en_dt : vdetail.sale_en_dt,
                exp_dt : vdetail.exp_dt,
                expire_date : vdetail.expire_date
            }
        }else{
            let vdetail:booksDetailType = await store.state.voucherState.bookAPI!.getProductDetails({
                channel: store.state.voucherState.channel,
                goods_code : payload.id
            })
            console.log(vdetail)
            let calPrice = 100 * parseFloat(vdetail.sale_price) / (100 - parseFloat(vdetail.dc_rate));
            detail ={
                name : vdetail.books_name,
                goods_code: vdetail.books_code,
                image : vdetail.image,
                sale_price : calPrice.toFixed(2),
                price : parseFloat(vdetail.sale_price).toFixed(2),
                desc : vdetail.description,
                sale_en_dt : vdetail.sale_en_dt,
                goods : vdetail.goods
            }
        }

        // console.log(vdetail)
        // Set Voucher detail
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.VoucherIssue
            draft.voucherState.vdetail = detail
        }))
    }

export default {
    actions : {
        init,
        setVoucher,
        setVoucherCoupon,
        setVoucherIssue,
        updateIssues
    },

}