import { Store } from 'use-global-hook'
import produce from "immer";
import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData } from './echoss';

declare var echoss: any;

export interface TestFeatureAction {
    setStampingEffect:(payload:boolean)=>void
}


const setStampingEffect= (
    store:Store<EchossState, EchossAction>,
    payload : boolean ) => {
        store.setState( produce(store.state, (draft)=>{
            draft.stampInProgress = payload
        }))
    }

export default {
    actions : {
        setStampingEffect,
    }
}