import axios from 'axios';
// let SERVER_URL = 'https://test'

export const pointApi = ( SERVER_URL:string ) =>{
    
    return {
        checkCard : async(data : any )=>{
            const response = await axios.post(
                `${SERVER_URL}/fpt/points/duplication`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        generateCard : async(data: any  )=>{
            const response = await axios.post(
                `${SERVER_URL}/fpt/points`,
                {...data , lang:'en'}
            );
            return response.data.result
        },       
        getCardDetails : async(data: any , cardno :string )=>{
            const response = await axios.get(
                `${SERVER_URL}/fpt/points/${cardno}`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        
        setPointStamp : async(data : any )=>{
            const response = await axios.post(
                `${SERVER_URL}/fpt/points/stamp`,
                {...data , lang:'en'}
            );
            console.log(response)
            return response.data
        },
        getPointHistory : async(data : any )=>{
            const response = await axios.post(
                `${SERVER_URL}/fpt/points/list`,
                {...data , lang:'en'}
            );
            if(!response.data.result) return {issues : []}
            return response.data.result
        },
        getOTP : async(data : any )=>{
            const response = await axios.get(
                `${SERVER_URL}/fpt/otp/${data}`,
                // { lang:'en'}
            );
            if(!response.data.result) return {issues : []}
            return response.data.result
        },

    }
} 
