import React from 'react'
//@ts-ignore
// import echoss from 'echoss/echoss-min';
// import echoss from '12cm-echoss-sdk/echoss/index.js';
import Swal from 'sweetalert'
import axios, { AxiosStatic } from "axios";
import produce from "immer";
import globalHook, { Store } from 'use-global-hook'
import voucher , {VoucherAction, VoucherState, VoucherInitialState } from './voucher'
import stampcard, { StampCardAction } from './stampcard';
import roulette, { RouletteAction, RouletteState } from './roulette';
import test_feature, { TestFeatureAction } from './test_feature';
import pointcard , { PointCardAction, PointCardInterface } from './pointcard';

import { safeLoad } from "js-yaml";
import payment, { paymentType , PaymentAction} from './payment';
import swal from 'sweetalert';
import { commonApi } from 'services/common';
import apiCall, { ApiState, ApiCallAction } from './apiCall';
import rally, { RallyState, RallyAction } from './rally';

declare var echoss: any;


export type StampCardResp ={
    accuCount: string
    accuList: any[]
    accuOffImgUrl: string
    accuOnImgUrl: string
    address: string
    allCount: string
    allStampAccuCnt: string
    benefit: string
    bgImgUrl: string
    brand: string
    cardCpnCount: string
    cardCpnList: any[]
    cardImgUrl: string
    cardName: string
    cardNo: string
    category: string
    city: string
    cpiAccuCount: string
    cpiBenefitCd: string
    cpiCd: string
    cpiCorrectYn: string
    cpiYn: string
    cpnIsuSumCnt: string
    cpnIsuYn: string
    custCpnCount: string
    custCpnList: any[]
    description: string
    excptMerchant: string
    existYn: string
    expDate: string
    expTime: string
    homepage: string
    imgUrl: string
    integYn: string
    key: string
    latitude: string
    longitude: string
    merchant: string
    name: string
    notice: string
    tel: string
}

export type CouponType ={
    cancelDate: string,
    cancelTime: string,
    code: string,
    description: string,
    expDate: string,
    expTime: string,
    imgDetailUrl: string,
    imgUrl: string,
    issueDate: string,
    issueNo: string,
    issueTime: string,
    name: string,
    status: string,
    useDate: string,
    useTime: string,
    expiredDate? : Date,
}

export enum EchossMode {
    StampCard,
    StampCardCoupon,
    StampCardCancel,
    StampRally,
    Roulette,
    RouletteCoupon,
    IssueCoupon,
    RedeemCoupon,
    StampHistory,

    Voucher,
    VoucherCoupon,
    VoucherIssue,

    StampPointCard,
    
    StampDisabled,

    RallyRedeem    
}

interface EchossErrorInterface {
    errorCode: string,
    errorMessage: string
}

export type BioData = {
    id :string,
    accessToken?: string,
    email? : string,
    name? : string,
}

type EchossError = {
    errorCode: string,
    errorMessage: string
}

type SubMainType = {
    Text: string,
    Image? : string,
    ClassName: string
}
export type MainButtonType = {
    Type : string
    Href : string
    Text: string,
    Image? : string,
    ClassName: string
}
type MainType = {
  BackGround : string
  Logo? : string, 
  Text? : string,
  Button : MainButtonType [],
  StampCard : SubMainType,
  Roulette  : SubMainType,
  MCoupon   : SubMainType,
  Voucher   : SubMainType,
  PointCard : SubMainType
}

export type StampCardConfigType = {
    ShowText? : string,
    CollectInfoMsg? : string,
    CollectInfo? : string[] ,
    CollectHideSkip? : boolean,
    PrivacyPolicy? : string,
    StampCount? : number,
    BrandName? : string,
    Button? : MainButtonType,
    ClientId? : string

    LimitStampCard? : boolean
}

type PointCardConfigType = {
    ShowTitle? : string,
    StampAuth : {
        Text: string, 
        Image? : string,
        ClassName: string
    },
    History : {
        Text: string, 
        Image? : string,
        ClassName: string
    }
}

type VoucherConfigType ={
    InboxCover  : string,
    ShopCover   : string,
    HeaderIcon  : string,
    api_url : string,
    HideCopyLink? :boolean,
    DisableStampPurc? : boolean
}

type RouletteConfigType ={
    Alert_Img  : string
}

export type ConfigType ={
    FBAPPID? :string,
    GAPPID? :string,
    PIXELID?: string,
    TITLE :string,

    PC_API_URL : string, 
    API_KEY :string,
    FUNC_KEY? :string [],
    SCARD_FKEY? :string [],
    BRAND_ID? : string,
    VOUCHER_CHANNEL? :string,
    POINTCARD_KEY? :string,
    
    OTP_DIV_CD_SAVE_UP :string,
    OTP_DIV_CODE_CANCEL_SAVING_UP :string,
    OTP_DIV_CD_RES_SAVE_UP :string,
    OTP_DIV_CD_USE_COUPON :string,
    appname : string,
    BrandName : string,
    Main : MainType,
    PointCard? : PointCardConfigType,
    Voucher? : VoucherConfigType,
    
    StampCard? : StampCardConfigType[],
    Roulette? : RouletteType[],
    Rally? : RallyType[],
    StampSound? :string

    redirect? : string,
    payment? : {
        stripe_key? : string,
        checkout_url : string,
        senangpayUrl? : string
        webhookUrl? : string
        err_msg? :string
    }
    SkipLoginPath : string[],

    facebookVersion?: string
}

type userInfoType = {
    "id" :string,
    "name" :string,
    "email" :string,
    "ctn"? :string,
    "gen" :string,
    "os" :string,
    "type" :string,
    "date" :string,
    "time" :string,
    "equip"? : string
    "birth"? : string
}

export interface EchossState {
    stampInitDone :boolean;
    stampInProgress : boolean;
    stampCardResp? : StampCardResp;
    stampError : boolean;

    newCoupon : boolean;
    customerID?: string|null;
    coupon: CouponType;
    canStamp?: boolean;        // Stamping allowed
    canCoupon?: boolean;       // Allow Issue coupon
    useCoupon?: boolean;       // Allow Use Coupon
    canPrize?: boolean;        // Win Prize
    canReset?: boolean;        // Allow Reset
    mode?: EchossMode;
    couponList : CouponType[];
    voucherState : VoucherState;
    rouletteState? : RouletteState;
    config? : ConfigType,
    userInfo? : userInfoType,
    pointCard : PointCardInterface
    payment? : paymentType
    loginBy : string

    Roulette : RouletteType
    StampCard : StampCardType
    commonApi? : any

    Rally : RallyState
    
    ApiState : ApiState 
}

type StampCardType = {
    SCARD_FKEY? : string
    sid? : number
    allCount? : number
    allStampAccuCnt? : number
    LimitStampCard? : boolean
}

type RouletteType ={
    FUNC_KEY? : string
    Alert_Img? : string
    Alert_Msg? : string
    Fail_Img? : string
    Fail_Msg? : string
    HidePin? : boolean

    UsedImg? : string
    TOS? : string
    // AdHoc
    RedirectOnCouponUsed? : string 
}

type RallyType ={
    FUNC_KEY? : string
    UsedImg? : string
    TOS? : string
}


export interface EchossAction extends 
    StampCardAction, RouletteAction, PointCardAction,  TestFeatureAction {

    init: (payload: string) => void

    signin: (payload: BioData ) => void
    signup: (payload: BioData ) => void
    updateUserInfo: (payload: any ) => void
    getUserInfo: (userId: string) => void

    echossInitSuccess: () => void
    stampBeforeStamp: () => void
    stampOnStamp: (payload: any) => void
    stampOnException: () => void
    onError: (errorCode: string, errorMessage: string) => void
    setStampDisable: () => void

    getCouponList: (payload: string) => void
    payment : PaymentAction
    voucher : VoucherAction
    apiCall : ApiCallAction
    rally : RallyAction

}

const initCoupon = {
    cancelDate : "",
    cancelTime : "",
    code : "",
    description : "",
    expDate : "",
    expTime : "",
    imgDetailUrl : "",
    imgUrl : "",
    issueDate : "",
    issueNo : "",
    issueTime : "",
    name : "",
    status : "",
    useDate : "",
    useTime : "",
    API_URL : ""
    // userInfo : {}
}


const initialState : EchossState = {
    customerID: null,
    stampInitDone : false,
    stampInProgress : false,
    stampError: false,
    newCoupon : false,
    mode: EchossMode.StampDisabled ,
    canStamp: false,
    canCoupon: false,
    useCoupon: false,
    canPrize: false,
    canReset: false,
    coupon: initCoupon,
    couponList : [],
    voucherState : VoucherInitialState,
    pointCard : {},
    
    loginBy : "",
    Roulette :{}, 
    StampCard :{},
    ApiState : {},
    Rally : {}
}


// [O-2] Set data -> Components (Division code, Customer ID, Store key, Coupon issue number)
export function set_aprvData(otpDivCd: any, custId: any, funcKey: any, isuNo: any | undefined = undefined) {
    var aprvData = otpDivCd + "," + custId + "," + funcKey;
    if (isuNo !== undefined) {
        aprvData += ("," + isuNo);
    }
    else {
        aprvData += ("," + "0");
    }
    return aprvData;
}
const signin = (
    store:Store<EchossState, EchossAction>, 
    payload: BioData) =>{
        echoss.User.checkDuplication({id:payload.id
        }, (result:any)=>{
            console.log(result)
            if(result.dup === "N") store.actions.signup(payload)
            else store.actions.getUserInfo(payload.id)
        }, store.actions.onError)
    }


// User getInfo
const signup = (
        store:Store<EchossState, EchossAction>, 
        payload: BioData) =>{

        let osType = 'A';
        if (!navigator.userAgent.match(/Android/i)) {
            osType = 'I';
        }

        // localStorage.clear();

        echoss.User.signupAndGetInfo({
            id: payload.id,
            os: osType,
            equip: "equip",
            type: "2",
            email: payload.email,
            name: payload.name,
            accessToken : payload.accessToken
        }, function (result:any) {
            console.log(result)
            store.setState(produce(store.state, (draft)=>{
                draft.customerID =result.id
                draft.userInfo = result
                draft.loginBy = result.id.length > 18 ? "google" : "fb"
                
            }) )

            store.actions.getUserInfo(payload.id)

            // devlog(result);    
            // let baseURI = document.baseURI.split("/")
            // console.log(baseURI) 
            // localStorage.setItem( baseURI[baseURI.length-2] , payload.id);
            console.log(result)
        }, store,actions.onError);
    }


const updateUserInfo= (
    store:Store<EchossState, EchossAction>,
    payload:any )=>{
        let data = produce( store.state, (draft) =>{
            draft.userInfo!.equip = payload.equip || "equip"
            if (payload.phone) draft.userInfo!.ctn = payload.phone
            if (payload.name) draft.userInfo!.name = payload.name
            if (payload.email) draft.userInfo!.email = payload.email
            // if (payload.gen) draft.userInfo!.gen = payload.gen
        })
        console.log(data)
        // data.ctn = payload.phone
                
        echoss.User.changeInfo ( { ...data.userInfo , key : store.state.config!.API_KEY } , ( )=>{
        // echoss.User.changeInfo ( data.userInfo , ( )=>{
            store.actions.getUserInfo(store.state.customerID!)
            // if feature enabled
            // store.actions.setRegisInfo('updated')
        }, store.actions.onError)
    }


const getUserInfo = (
    store:Store<EchossState, EchossAction> ,
    userId: string ) =>{
    // console.log(userId)
    echoss.User.getInfo({
        id: userId,
     }, function (result:any) {
        store.setState(produce(store.state, (draft)=>{
            draft.customerID =result.id
            draft.userInfo = result
            draft.loginBy = result.id.length > 18 ? "google" : "fb"
        }) )
    }, store,actions.onError);
}


const init= async (
        store:Store<EchossState, EchossAction>, 
        payload :string) => {
        let config_file = await axios.get( document.baseURI + '/config.yaml', {
            headers: { 
                Pragma: 'no-cache', 
                'Cache-Control': 'no-cache',
            },
            
        })
        let config:ConfigType = safeLoad(config_file.data) as ConfigType
        console.log(config)
        
        // let userId = localStorage.getItem(baseuri)
        
        store.setState(produce(store.state, (draft)=>{
            // draft.customerID = userId
            draft.config = config        
            draft.commonApi = commonApi("https://dev-pc-echoss.zvolution.com.my")
        }))
        // Assign callback
        echoss.initializeSuccess = store.actions.echossInitSuccess
        echoss.Stamp.onBeforeStamp = store.actions.stampBeforeStamp
        echoss.Stamp.onStamp = store.actions.stampOnStamp

        echoss.Stamp.onException = store.actions.onError
        echoss.Stamp.onError = store.actions.onError

        // echoss Init
        echoss.setLanguageCode(echoss.Common.LANGUAGE_CODE_TYPE.ENGLISH)
        // echoss.initialize(API_KEY, echoss.REGION_CODE_TYPE.KOREA, 'container')
        echoss.initialize(config.API_KEY, echoss.REGION_CODE_TYPE.KOREA)
        console.log(echoss)


        
        // Init Payment
        if (config.VOUCHER_CHANNEL)
            store.actions.voucher.init(config.VOUCHER_CHANNEL)
        if (config.payment)
            store.actions.payment.init(config)
    }

const echossInitSuccess = (
    store:Store<EchossState, EchossAction> ) => {
        console.log('echoss: init_success')
        // Initialize echoss icon
        echoss.Icon.init();
        // echoss.Icon.showIcon();
        // let baseuri = getBaseURI()
        
        // let userId = localStorage.getItem(baseuri)
        // if (userId) store.actions.getUserInfo(userId!)


        // Stamp Init
        echoss.Stamp.init((payload:any, payload2:any) => {
            // console.log(payload)
            // store.actions.setOTP('test')
            store.setState(produce ( store.state, (draft)=>{
                draft.stampInitDone = true
            }))
        },
            store.actions.onError
        )
    }
const stampBeforeStamp= (
    store:Store<EchossState, EchossAction>) => {
        console.log('before stamp')
    }
const stampOnException= (
        store:Store<EchossState, EchossAction> ) => {
        console.log('test')
    }
const stampOnStamp= async (
    store:Store<EchossState, EchossAction>, 
    payload:any) => {
        console.log(store)

        let config = store.state.config!
        // let canStamp = store.state.canStamp

        if ([EchossMode.Voucher, EchossMode.StampDisabled].includes(store.state.mode! ) ) {
            console.log('return')
            return 
        }

        if (store.state.stampInProgress) {
            return
        }
        store.setState(produce(store.state, (draft)=>{
            draft.stampInProgress = true
        }))


        if (store.state.mode === EchossMode.StampCard) {
            console.log('enter stamp')

            if ( store.state.StampCard.LimitStampCard && store.state.StampCard.allStampAccuCnt! >= store.state.StampCard.allCount! )  {
                swal(`You have reach maximum stamps. (${store.state.stampCardResp!.allStampAccuCnt}/${store.state.stampCardResp!.allCount})`);
                store.setState ( produce (store.state, (draft)=>{
                    draft.stampInProgress = false
                })) 
                return
            }

            echoss.Stampcard.collect({
                id: store.state.customerID,
                funckey: store.state.StampCard.SCARD_FKEY,
                stampParams : payload,
            
            }, store.actions.stampAccumulated,
                store.actions.onError
            );
        }

        if (store.state.mode === EchossMode.StampCardCoupon) {
            echoss.Stampcard.useCoupon({
                id: store.state.customerID,
                funckey: store.state.StampCard.SCARD_FKEY,
                issueNo: store.state.coupon.issueNo,
                stampParams : payload
            },
                function (result: any) {
                    console.log(result)
                    store.setState ( produce (store.state, (draft)=>{
                        draft.stampInProgress = false
                        draft.coupon.status = '2'
                    }))
                },
                store.actions.onError
            );
        }

        if (store.state.mode === EchossMode.StampCardCancel) {
            echoss.Stampcard.cancelCollecting ({
                    id 		    : store.state.customerID,
                    funckey 	: store.state.StampCard.SCARD_FKEY,
                    stampParams : payload
                }, function(result:any) {
                    console.log(result)
                    store.setState ( produce (store.state, (draft)=>{
                        draft.stampInProgress = false
                        draft.stampCardResp = result
                        draft.couponList = result.custCpnList
                    }))
                }, store.actions.onError
            )
        }

        if (store.state.mode === EchossMode.Roulette) {
            console.log('enter')
            let final:any = null

            console.log('enter coupon')
            console.log(payload)
            let res = await store.state.commonApi.getStamp({
                version : payload.version,
                eq : payload.eq,
                s : payload.s,
                c : payload.c,
                p : payload.p,
                key : store.state.config!.API_KEY,
                funcKey : store.state.Roulette.FUNC_KEY
            })
            console.log(res)
            
            echoss.Stamproulette.getDetailinfo({
                id: store.state.customerID,
                funckey: store.state.Roulette.FUNC_KEY,
                type: config.OTP_DIV_CD_RES_SAVE_UP,
                stampParams: payload
            }, store.actions.issueRouletteCoupon ,
                (error:string, errormsg:string)=>{
                    // clearTimeout(timer)
                    setTimeout ( 
                        ()=> store.actions.onError(error, errormsg),
                        500)
                }
            );
        }

        if (store.state.mode === EchossMode.RouletteCoupon) {
            echoss.Stamproulette.useCoupon(
                {
                    id: store.state.customerID,
                    funckey: store.state.Roulette.FUNC_KEY,
                    issueNo: store.state.coupon.issueNo,
                    stampParams: payload
                },
                function (result: any) {
                    console.log(result)
                    store.setState ( produce (store.state, (draft)=>{
                        draft.stampInProgress = false
                        draft.coupon.status = '2'
                    }))
                    // used coupon, update coupon list
                },
                store.actions.onError
            )
        }


        if (store.state.mode === EchossMode.VoucherCoupon) {
            if(!store.state.voucherState.idetail) {
                return
            }
            console.log('enter coupon')
            console.log(payload )
            // let stamp = 
            let res = await store.state.voucherState.productAPI!.stampUse({
                c : payload.c!,
                p : payload.p!,
                s : payload.s!,
                eq : payload.equipTyp!,
                version: payload.version!,

                channel : store.state.voucherState.channel,
                // cid : store.state.customerID!, 
                goods_div_cd : 1,

                ticket_type : store.state.voucherState.idetail.books_code ? 2: 1,
                ticket_no: store.state.voucherState.idetail.ticket_no,
                brand_id : store.state.voucherState.idetail.brand_id,
                // use_price? : string,
                // aprv_no? : string,
                sc_key : store.state.config!.API_KEY
            })
            console.log(res)
            
            if (res.resCd !== "0000") { 
                store.actions.onError(res.resCd, res.resMsg)
            }else {
                // check if responst is not valid throw error
                store.setState ( produce (store.state, (draft)=>{
                    draft.mode = EchossMode.StampDisabled
                    draft.voucherState.idetail!.used = true
                    draft.stampInProgress = false
                }))
                store.actions.voucher.updateIssues()
            }
        }



        if (store.state.mode === EchossMode.VoucherIssue) {
            if (store.state.config!.Voucher!.DisableStampPurc) {
                store.setState ( produce (store.state, (draft)=>{
                    draft.stampInProgress = false
                }))
                swal({
                    icon : "error",
                    text : "Not Supported",
                    buttons :[false]
                })
                return
            }

            if(!store.state.voucherState.vdetail) {
                return
            }
            console.log('enter coupon')
            console.log(payload )
            // let stamp = 
            let res = await store.state.voucherState!.productAPI!.stampIssue({
                c : payload.c!,
                p : payload.p!,
                s : payload.s!,
                eq : payload.equipTyp!,
                version: payload.version!,

                brand_id: store.state.voucherState.vdetail.brand_id!,
                channel : store.state.voucherState.channel,
                cid : store.state.customerID!, 
                auth_div_cd : 1,
                trx_no : String(Date.now()),
                goods_code : store.state.voucherState.vdetail.goods_code!,
                sc_key : store.state.config!.API_KEY
            })

            if (res.resCd !== "0000") {
                store.actions.onError(res.resCd , res.resMsg )
            } else {
                store.setState ( produce (store.state, (draft)=>{
                    draft.stampInProgress = false
                }))
                swal({
                    icon : "success",
                    text : "Purchase Success",
                    buttons :[false]
                })
            }
        }

        if (store.state.mode === EchossMode.StampPointCard ) {
            console.log("enter stamp")
            await store.actions.onStampPointCard(payload)

            store.setState ( produce (store.state, (draft)=>{
                draft.stampInProgress = false
            }))
        }
        // console.log(`test ${canStamp}`)
    }

const onError= async (
    store:Store<EchossState, EchossAction>, 
    errorCode:string, errorMessage:string) => {
        store.setState(produce(store.state, (draft)=>{
            draft.stampInProgress = false
            draft.stampError = true
        }))
        await Swal({
            icon : "error",
            title : "Ops..",
            text : `${errorMessage} :  (${errorCode})`
        });
        // alert(`${errorMessage} :  (${errorCode})`)
        store.setState(produce(store.state, (draft)=>{
            draft.stampError = false
        }))
    }

const setStampDisable = (
    store:Store<EchossState, EchossAction>, 
    payload:string) => {
        echoss.Icon.hideIcon()

        store.setState( produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampDisabled
        }))
    }
        

export const actions = {
    init,
    signin,
    signup,
    getUserInfo,
    updateUserInfo,

    echossInitSuccess,
    stampBeforeStamp,
    stampOnStamp,
    stampOnException,
    onError,

    setStampDisable,

    ...roulette.actions,
    ...stampcard.actions,    
    voucher : voucher.actions,
    ...pointcard.actions,
    ...test_feature.actions,
    payment : payment.actions,
    apiCall : apiCall.actions,
    rally : rally.actions,
}

export const useEchossHooks = globalHook<EchossState, EchossAction>(React , initialState, actions)
// export default {echoss_m : echoss_m}