import { Store } from 'use-global-hook'
import produce from "immer";

import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData, actions } from './echoss';
import { pointApi } from 'services/pointcard';

declare var echoss: any;

export const pointAPI = (process.env.NODE_ENV ==='production') ? pointApi('https://pm-echoss.zvolution.com.my') : pointApi('https://dev-pm-echoss.zvolution.com.my')

export enum PointCardMode {
    PointCard,
    StampAuth,
    CollectSuccess,
    UseSuccess,
    PointHistory
}

export type PointCardType ={
    cardNo : string
    id	: string
    date	: string
    time	: string
    points	: string
    status	: string
}

export type StampResultType = {
    authNo : string
    cardNo : string
    p: string 
    points: string 
    shopId: string 
    shopNm: string 
    trxDiv: string 
    trxNo: string 
}

export type PointCardRange ={
    startDate : string,
    endDate : string
}
export interface PointCardInterface {
    mode? : PointCardMode,
    cardNo? : string,
    cardKey? : string,
    status? : PointCardType,
    pointHistory? : any [], //pointTxType [],
    stampResult? : StampResultType 
}

export type pointHistTxType = {
    date: string,
    merchant: string,
    point: string,
    sign: string,
    time: string, 
    trxNo: string, 
    type: keyof typeof trx_enum 
}

export enum trx_enum  {
	DA = "Accumulate",
	CA = "Cancel Accumulate",
	DU = "Use",
	CU = "Cancel Use"
}

export interface PointCardAction {
    setPointMode: (payload: PointCardMode )=> void ,
    setPointCard:(payload:string)=>void
    setStampPointCard:(payload:string)=>void

    updatePointCardHistory:(payload:PointCardRange)=>void
    updatePointCard:()=>void

    onStampPointCard: (payload:any)=> Promise<void>
    clearPointCardResult: ()=> void
    // setStampHistory:()=>void
}

const updatePointCard = async(
    store:Store<EchossState, EchossAction> ,
    payload : string) => {
        const config = store.state.config!
        const result = await pointAPI.getCardDetails({
            key : config.API_KEY,
            pointKey : config.POINTCARD_KEY,
        }, store.state.pointCard.cardKey! )

        store.setState(produce(store.state, (draft)=>{
            draft.pointCard.status = result
        }))
    }

const updatePointCardHistory = async(
    store:Store<EchossState, EchossAction> ,
    payload : PointCardRange ) => {
        const config = store.state.config!
        const result = await pointAPI.getPointHistory( {
            key : config.API_KEY,
            pointKey : config.POINTCARD_KEY,
            cardKey : store.state.pointCard.cardKey,
            cardNo : store.state.pointCard.cardKey,
            pdc : 'U',
            id : store.state.customerID,
            startDate : payload.startDate,
            endDate : payload.endDate
        })
        // store.actions.updateStampCardStamp(result)
        console.log(result)
        
        store.setState(produce(store.state, (draft)=>{
            draft.pointCard.pointHistory = result.list
        }))
        // store.actions.onError)
    }


const setPointMode = async(
    store:Store<EchossState, EchossAction>, 
    payload:PointCardMode ) => {
        store.setState(produce(store.state, (draft)=>{
            // draft.mode = payload
        }))
    }

const setPointCard = async(
    store:Store<EchossState, EchossAction>, 
    payload:any) => {
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampDisabled
        }))

        const config = store.state.config!
        // check if card id is set, return

        // getUserId to get point card
        let result = await pointAPI.checkCard({
            key : config.API_KEY,
            pointKey : config.POINTCARD_KEY,
            id : store.state.customerID!
        })
        if (result.dup !== "Y" ) {
            result = await pointAPI.generateCard({
                key : config.API_KEY,
                pointKey : config.POINTCARD_KEY,
                id : store.state.customerID!
            })
        }
        
        store.setState(produce(store.state, (draft)=>{
            draft.pointCard.cardKey = result.cardKey
            draft.pointCard.cardNo = result.cardNo
        }))

        store.actions.updatePointCard();
    }

const setStampPointCard = (
    store:Store<EchossState, EchossAction>) => {
        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: `${store.state.pointCard.cardKey},${store.state.config!.POINTCARD_KEY}`,
            funcCd      : "FPT",
            isuDivCd	: echoss.Icon.OTP_ISSUE_TYPE.COMMON_ETC,
            cntYn 		: "N",
        }, async (result:any)=>{
            
            let data = await pointAPI.getOTP(store.state.pointCard.cardKey!)
            console.log(data)
            // set to stampcard state initiate  user close coupon popup
            store.setState ( produce (store.state, (draft)=>{
                draft.stampInProgress = false
                draft.pointCard.stampResult = data 
            }))
        }, (errCode:any, errMsg:any)=>{ 
            // store.setState ( produce (store.state, (draft)=>{
            //     draft.pointCard.stampResult = undefined 
            // }))
            store.actions.onError(errCode, errMsg) }
        )
        // console.log('stamp mode')
        // store.actions.updatePointCard()
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampPointCard
        }))
        // store.actions.updatePointCard()
    }

const onStampPointCard = async(
    store:Store<EchossState, EchossAction>, 
    payload: any ) => {
        const config = store.state.config!
        const data = await pointAPI.setPointStamp({
            key : config.API_KEY,
            pointKey : config.POINTCARD_KEY,
            id : store.state.customerID!,
            cardKey : store.state.pointCard.cardKey,

            c : payload.c!,
            p : payload.p!,
            s : payload.s!,
            eq : echoss.Common.EQUIP_TYPE.WEB, //payload.equipTyp!,
            version: payload.version!,
        })
        if ( !data.result) {
            store.actions.onError(data.resCd, data.resMsg)
            return
        }
        
        store.setState(produce(store.state, (draft)=>{
            draft.pointCard.stampResult = data.result
        }))
    }   


const clearPointCardResult = async(
    store:Store<EchossState, EchossAction>, 
    payload: any ) => {
        store.setState ( produce (store.state, (draft)=>{
            draft.pointCard.stampResult = undefined 
        }))
    }

export default {
    actions : {
        setPointMode,
        setPointCard,
        setStampPointCard,
        updatePointCardHistory,
        updatePointCard,
        onStampPointCard,
        clearPointCardResult
    }
}