import React, { useEffect } from 'react';
import { EchossAction, EchossState, useEchossHooks } from 'globalhooks/echoss';
import { TransactionError  } from 'modules/TransactionError/Item';
import { useHistory } from 'react-router';

interface PageProps {
}


const PageErrorList: React.FC <PageProps>= () => {
  const [ echossState , echossAction] = useEchossHooks<EchossState, EchossAction>((st)=>st, (act)=>act);
  useEffect(()=>{
    if (echossState.config && echossState.customerID)
    echossAction.apiCall.getTransactionError() 
  },[echossState.config, echossState.customerID])
  const history = useHistory()  

  let txlist = echossState.ApiState.TransactionErrorList
  // const {} = 
  //  get data from zvo  
  //  build list based on query
  let txListItem : JSX.Element []  = []

  if (txlist) {
    if (txlist.length > 0 ) {
      txListItem = txlist.map ( (item)=> <TransactionError key={item.id} Info={item}  handleClick={()=>{}} />)
    }
  }
  return (
    <>
    <div className="nav_bar back_area back_roulette">
      <img className="nav_bar_back" src="images/icon_back_b.png" alt="icon_back_b" onClick={()=> history.goBack()} />
      <span className="nav_bar_title" style={{color:'black'}}>Transaction Error Logs</span>
    </div>
    <div className="txerror_list"> 
      {txListItem}
    </div>
    </> 
  );
}

export default PageErrorList
