import React, { useEffect , lazy, Suspense, useState} from 'react';
// import { Router, useLocation } from "@reach/router";
import { BrowserRouter as Router, Route , Switch , useLocation } from "react-router-dom";
import swal from "sweetalert"
// import './App.css';
import 'antd-mobile/dist/antd-mobile.css';  // or 'antd-mobile/dist/antd-mobile.less'
import ReactPixel from 'react-facebook-pixel';

import {Stamping} from 'modules/Stamping';

import {useEchossHooks, EchossAction, EchossState, ConfigType} from './globalhooks/echoss'
// import { NavBar, Icon } from 'antd-mobile';
import { Login } from 'modules/Login';
import { Loading } from 'modules/Loading';
import { log } from 'utils/logger';
import { getBaseURI } from 'utils/misc';
import { useTransition , animated } from 'react-spring';
import PageErrorList from 'pages/txerror';
// import  Roulette  from "pages/roulette";
// import Landing from "pages/landing";
const Main = lazy(()=>import('pages/main'))
const Roulette = lazy(()=>import('pages/roulette'))
const CardPage = lazy(()=>import('./pages/cards'))
const MCoupon = lazy(()=>import('./pages/mcoupon'))
const Voucher = lazy(()=>import('./pages/voucher'))
const Rally = lazy(()=>import('./pages/rally'))
const VDetail = lazy(()=>import('./pages/vdetail'))
const VLink = lazy(()=>import('./pages/vlink'))
const NotFound = lazy(()=>import('./pages/404'))
const Landing = lazy(()=>import('./pages/landing'))
// const {Outlet} = lazy(()=>import('./pages/landing'))
const PointCard = lazy (()=>import('pages/pointcard'));
const UserInfo = lazy(()=>import('./pages/userInfo'));

var baseURI = '/'
if (process.env.NODE_ENV ==='production') {
  baseURI = getBaseURI()
  baseURI = baseURI === window.location.host ? '/' : baseURI
}
const App: React.FC = () => {
  let [customerID, echossActions] = useEchossHooks<string|null|undefined,EchossAction>((state:EchossState)=>state.customerID , (act)=>act as EchossAction ) 
  let [stampingInProgress ] = useEchossHooks<boolean,EchossAction>((state:EchossState)=>state.stampInProgress , (act)=>act )
  let [config ] = useEchossHooks<ConfigType|undefined,EchossAction>((state:EchossState)=>state.config ,(act)=>act )
  let [stampError ] = useEchossHooks<boolean,EchossAction>((state:EchossState)=>state.stampError ,(act)=>act )
  let location = useLocation()

  let transitions = useTransition( location, location=>location.pathname ,{
    from: { opacity: 0, transform: 'translate3d(100%,0,0)', height:"100%"},
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' , height:"100%"},
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' , height:"100%"},
  })

  log.debug(customerID)
  
  useEffect( ()=>{
    echossActions.init('reset')
  }, [echossActions])

  useEffect(()=>{
    if (config) {
      if (config.PIXELID)
        ReactPixel.init(config.PIXELID)
    }

  },[config])
  if (!config) {
    return <Loading />
  }

  let signedIn :boolean = customerID ? true : false;
  console.log(signedIn)
  console.log(config)
  document.title = config.TITLE

  console.log(window.location)
  let params = new URLSearchParams(window.location.search)
  // if (params.get('login') === 'fb') 

  return (
    <div style={{
      height:'100vh', 
      display:'flex', 
      flexFlow:'column',
      maxWidth: '640px',
      margin: 'auto'
    }}>
      {/* { transitions.map(({item, props, key })=>(
      <animated.div key={ key } style={props} > */}
      
      <Suspense fallback={<Loading />}>
        {/* <Switch location={item}> */}
        <Switch  >
          {/* <Router location={transitions.item} style={{height:'100%'}} basepath={baseURI}>   */}
          {/* <Main path="/" /> */}
          <Route component={Roulette} path="/roulette/:id" />
          <Route component={Roulette} path="/roulette" />
          <Route component={CardPage} path="/card/:id"/>
          <Route component={CardPage} path="/card"/>

          <Route component={MCoupon} path="/mcoupon"/>

          <Route component={PointCard} path="/pointcard/*" />
          
          <Route component={VLink} path="/voucher/link/:ptype/:id" /> 
          <Route component={VDetail} path="/voucher/detail/:ptype/:id" />
          <Route component={Voucher} path="/voucher/success" />
          <Route component={Voucher} path="/voucher/cancel" />
          <Route component={Voucher} path="/voucher" />
          <Route component={Rally} path="/rally" />
          
          <Route component={UserInfo} path="/user/update" />
          <Route component={PageErrorList} path="/txissue" />
          <Route component={UserInfo} path="/txissue/:id" />
         
          {/* <NotFound default /> */}

          <Route component={Landing} path="/" />
        </Switch>
      </Suspense>
      {/* </animated.div>
      ))} */}

      {/* Popups for login  */}
      <Login 
        redirect = {config.redirect}
        GAPPID={config.GAPPID} 
        FBAPPID={config.FBAPPID}
        signedIn={signedIn}
        onSignIn={echossActions.signin}
        onError = {(resp)=> swal(JSON.stringify(resp))}
        autoload = { params.get('login') } 
        skip_path = {config.SkipLoginPath}
        pathname = {window.location.pathname}
        facebookVersion = {config.facebookVersion || "12.0"}
      />

      {/* <div id="stampingbutton" 
        style={{zIndex:1000}} 
        onClick={()=>echossActions.setStampingEffect(!stampingInProgress)} > Test button </div>  */}

      <Stamping stamping={stampingInProgress} delay={1500} soundPath={config.StampSound!} error={stampError} />
      {/* <Stamping stamping={stampCall} delay={3000}/> */}
      {/* Echoss Icon Overlay */}
      <div id='container' ></div>
    </div>
  );
}
const AppWrapper = ()=>(
  <Router basename={baseURI} >
    <App />
  </Router>  
);

export default AppWrapper