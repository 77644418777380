import axios from 'axios';
// let SERVER_URL = 'https://test'

type MCouponListParams = {
    key: string,
    agentId : string
}


type MCouponDetailParams = {
    key: string,
    userId: string,
    couponId: string,
    couponNo: string 

}
type MIssueCouponParams = {

    sendType: string
    userId: string,
    couponId: string   
}

type FRTCouponParams = {
    key: string,
    id: string, // cust_id
    funckey: string,
    issueNo: string,
    type: string
}
export const mcouponApi = ( SERVER_URL:string ) =>{
    
    return {

        getCouponList : async(data : MCouponListParams )=>{
            const response = await axios.post(
                `${SERVER_URL}/fcs/api/agent/coupons`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        getUserCoupons : async(data : MCouponListParams )=>{
            const response = await axios.post(
                `${SERVER_URL}/fcs/api/coupons/history`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        getCouponDetails : async(data: MCouponDetailParams)=>{
            const response = await axios.post(
                `${SERVER_URL}/fcs/coupons/detail`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
        issueCouponList : async(data : MIssueCouponParams[] , key:string )=>{
            const response = await axios.post(
                `${SERVER_URL}/fcs/coupons`,
                { key : key, list : data , lang:'en'}
            );
            // if response.data.status != "2" , do not proceed to get coupon?
            return response.data.result
        },
        issueCouponFRT :  async(data : FRTCouponParams )=>{
            const response = await axios.post(
                `${SERVER_URL}/frt/coupon`,
                {...data , lang:'en'}
            );
            return response.data.result
        },
    }
} 
