import React, { useState, Fragment, useEffect } from 'react'
import {GoogleLogin, GoogleLoginResponse} from 'react-google-login'
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login'
import { BioData } from 'globalhooks/echoss';
// import { getBaseURI } from 'utils/misc';
import { initFB } from './facebook';
import { Loading } from 'modules/Loading';
import {TiSocialFacebook } from 'react-icons/ti'
import Axios from 'axios';
import swal from 'sweetalert';


interface Props {
    GAPPID? : string,
    FBAPPID? : string,
    signedIn : boolean,
    onSignIn : (data: any ) => void,
    onError : (resp : any) =>void
    autoload : string | null 
    redirect ? : string
    skip_path? : string[]
    pathname : string,
    // redirectUri : string
    facebookVersion?: string
}

export const Login: React.FC <Props> = ( { 
    signedIn, onSignIn, onError , FBAPPID, GAPPID, autoload, redirect, skip_path=[], pathname, facebookVersion})=>{    
    const [loginError, setLoginError] = useState(false)
    useEffect(()=>{
        // Ios safari login 
        // var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase()) ;//|| true;
        var isiDevice = true
        if (!signedIn &&  params.get('code') && isiDevice ) {

            let res = Axios.get("https://api.zvolution.com.my/echoss/fblogin", {
                params : {
                    fbid : FBAPPID,
                    code : params.get("code"),
                    redirect_url : document.baseURI
                }
            }).then((res)=>{
                console.log(res.data)
                onSignIn(res.data as BioData)
            }).catch((error)=>{
                swal("FB Login error")
            }) 
        }
    },[])

    if (  skip_path.find((path)=> pathname.search(path) >=0 ) ){
        console.log('skip login')
        return null
    }

    let visible = "flex"

    if (signedIn) visible ="none"
    // return null
    
    let handleError = (response : any)=>{
        setLoginError(true)
        console.log(response)
        onError(response)
    }
    let params = new URLSearchParams(window.location.search)
        
    if (autoload && !loginError)  visible ="none"
    
    if ( params.get('state') === 'facebookdirect' || params.get('code') )  visible ="none"
    
    if (redirect === 'true' && !signedIn) {
        if (!localStorage.getItem("redirect"))
            localStorage.setItem("redirect", window.location.pathname)
    }

    if (autoload ==='fb' && FBAPPID) {
        initFB(FBAPPID, facebookVersion || "12.0",  (response)=>{
            let data:BioData  = response
            onSignIn(data)
            console.log(response)
        })
        visible = "none"
    }
    if ( params.get('code') ) {
       return <div /> 
    }
    return (
        <Fragment>
        <div className="popup_area dimmed  " style={{
      
              display: visible,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            { GAPPID &&
                <GoogleLogin 
                    clientId={GAPPID}
                    
                    // buttonText={"Sign wtih Google"}
                    onSuccess={(response)=> {
            
                        let gresp = response as GoogleLoginResponse
                        let bprof = gresp.getBasicProfile()
                        let data = { 
                        id : bprof.getId(),
                        email : bprof.getEmail(),
                        name : bprof.getName(),
                        accessToken : gresp.getAuthResponse().access_token
                        }
                        onSignIn(data)
                        
                    }}
                    theme = "light"
                    // styles = {}
                    className= "login_button"
                    onFailure={(response)=>handleError(response) }
                    cookiePolicy={'single_host_origin'}
                    redirectUri = {document.baseURI}
                    autoLoad = { autoload === 'google'}
                >
                    <span style={{color:"black"}} >Login with Google</span>
                </GoogleLogin> }
            { FBAPPID &&
                <FacebookLogin
                    appId={FBAPPID}
                    fields="name,email,picture"
                    onClick={(response:any)=>console.log(response)}
                    callback={(response)=>{
                        let data:BioData  = response as ReactFacebookLoginInfo 
                        onSignIn(data)
                        console.log(response)
                    }} 
                    onFailure = { (error)=>{ handleError(error )} }
                    containerStyle={{width:"70%"  }}
                    buttonStyle = {{width:"100%"}}
                    redirectUri = { document.baseURI }
                    autoLoad={ autoload === 'fb' }
                    isMobile={true}
                    cssClass = "fb-css"
                    icon={<TiSocialFacebook className="fb_icon" size="32px" color="white"/>}
                    version={ facebookVersion || "12.0"}

                />
            }
                    {/* <button onClick={()=>{
                        let data:BioData  = {
                            id : "1234",
                            email : "test@g.com",
                            name : "testname"
                        }
                    onSignIn(data) 
                    }} >DEBUG LOGIN</button> */}
        </div>
        { (!signedIn && visible==='none' ) ? <Loading /> : null }
        </Fragment>
            
    );
}
