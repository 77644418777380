import React, { Fragment } from 'react';
// import TweenOne from 'rc-tween-one';
import {dateStringWithDelim} from 'utils/datetime'
import { TransactionErrorType } from 'globalhooks/apiCall';

export interface TransactionErrorProps {
    Info : TransactionErrorType, 
    handleClick : ()=>void
}

export const TransactionError : React.FC <TransactionErrorProps>= ({Info , handleClick})=>{
    let divStyle = {}

    return (
        <li className="txerror_item" onClick={handleClick}
            style={divStyle}>
            {/* <div className="coupon_brief_info"> */}
                {/* TXID, Date , Amount*/}
                <p className="coupon_brief_info_title"> 
                Transaction ID:   
                </p>
                <span>{Info.txid}</span>
                <p className="coupon_brief_info_subtext">
                    <span className="coupon_brief_info_subtitle">Date</span>
                </p>
                <span>{Info.error_date} </span>

                <p className="coupon_brief_info_subtext">
                    <span className="coupon_brief_info_subtitle">Amount</span>
                </p>
                <span>{Info.amount}</span>    
            {/* </div> */}
        </li>
    )
}
