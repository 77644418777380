import React, { useState, useEffect } from 'react';



const init = (sound:HTMLAudioElement):void => {
    var tapped = function() {
        sound.play()
        sound.pause()
        sound.currentTime = 0
        console.log('play sound')
        stop()

        // Play all audio files on the first tap and stop them immediately.
        // if(allAudio) {
        //     for(var audio of allAudio) {
        //         audio.play()
        //         audio.pause()
        //         audio.currentTime = 0
        //     }
        //     allAudio = null
        // }        
    }
    document.body.addEventListener("touchstart", tapped, false)
    document.body.addEventListener("click", tapped, false)
    
    var stop = function() {
        document.body.removeEventListener("touchstart", tapped, false)
        document.body.removeEventListener("click", tapped, false)
    }
}


type StampingProps = {
    stamping : boolean,
    error : boolean,
    delay : number,
    soundPath : string
}

let sound: HTMLAudioElement;
export const Stamping : React.FC<StampingProps> = ({stamping , delay , soundPath, error })=>{
    
    let [show, setShow] = useState(false)
    // const sound = new Audio("")
    // sound.src = soundPath
    //  Init audio with touchstart
    useEffect(()=>{
        if (soundPath) {
            sound = new Audio(soundPath)
            init(sound)
        }
    }, [] )


    useEffect(()=>{
        if (stamping){ 
            // sound.load()
            // sound.play()
            // sound.loop = true
            // var audio1 = document.getElementById('stamp_audio') as HTMLAudioElement
            // console.log(audio1)
            // if(audio1) audio1.play()
            if (sound) {
                sound.currentTime = 0
                sound.play()
            }

            setShow(true)
            setTimeout(()=>{
                setShow(false)
            }, delay)
        }
        if (error) {
            // setShow(false)
            // sound.pause()
        }
    },[stamping,delay, error])

    if (!show) return null
    
    return (
        <div className="popup_area dimmed flex_center" style={{
        }}>
            <img src='images/stamp_icon.gif' alt='Stamping...' className="popup" style={{height: "4rem", width:"4rem"}}/>
        </div>
    )
}