import { Store } from 'use-global-hook'
import produce from "immer";

import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData, actions } from './echoss';
import swal from 'sweetalert';
import { commonApi , userInfo} from 'services/common';

declare var echoss: any;

export interface StampCardAction {
    setStampCard:(payload:string)=>void
    setStampCardCancel:(payload:string)=>void
    setStampCardCoupon:(payload:CouponType)=>void
    setRegisInfo: (payload:string) =>void
    getStampCardInfo: () =>void
    updateStampCardStamp: (payload:any)=>void
    welcomeUpdateUserInfo : (payload: any ) => void
    stampAccumulated : (payload:any) => void
    // setStampHistory:()=>void
}

const stampAccumulated = ( 
    store:Store<EchossState, EchossAction>,
    payload : any ) => {
        console.log(payload)
        if (payload.cpnIsuYn === "N"){
            let accuCount = payload.accuCount === 0 ? payload.allCount : payload.accuCount.toString()

            let issueOutofQuota = payload.cardCpnList.filter((cpn:any)=> cpn.no === accuCount  )
            console.log(issueOutofQuota)
            if (issueOutofQuota.length > 0) swal("sorry , out of coupon")

        }else if (payload.cpnIsuYn === "Y") {
            swal("Coupon Issued!!")
        }else swal("error")

        store.actions.updateStampCardStamp(payload)
    }

const updateStampCardStamp =(
    store:Store<EchossState, EchossAction>,
    payload : any ) => {
            store.setState(produce(store.state, (draft)=>{
                draft.stampInProgress = false
                draft.stampCardResp = payload 
                draft.couponList = payload.custCpnList
                draft.StampCard.allCount = parseInt(payload.allCount)
                draft.StampCard.allStampAccuCnt = parseInt(payload.allStampAccuCnt)
                // draft.StampCard.LimitStampCard = payload.LimitStampCard
            }))
        
    }
const getStampCardInfo =(
    store:Store<EchossState, EchossAction> ) => {
        echoss.Stampcard.getDetailInfo( {
            id : store.state.customerID,
            funckey : store.state.StampCard.SCARD_FKEY
            // funckey : store.state.stampCardResp!.cardNo
        }, (result:any)=>{
            store.actions.updateStampCardStamp(result)
            console.log(result)
        }, store.actions.onError)
    }


const setStampCard = (
    store:Store<EchossState, EchossAction>, 
    payload:any) => {
        let config = store.state.config!
        let sid = parseInt(payload) || 0
        let SCARD_FKEY = store.state.config!.SCARD_FKEY![sid]

        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: set_aprvData(config.OTP_DIV_CD_SAVE_UP, store.state.customerID, SCARD_FKEY),
            funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_CARD,
            isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.COLLECT,
            cntYn 		: "Y",
        }, (result:any)=>{                    
            // store.actions.setStampCard('')
            store.actions.getStampCardInfo()
        }, store.actions.onError
        )

    // Request stamp card process and detailed information
        echoss.Stampcard.addAndGetDetailinfo ({
            id          : store.state.customerID, 
            funckey     : SCARD_FKEY,
            cntYn 		: "N",
        }, function(result:any) {
            // console.log(result)
            store.setState(produce(store.state, (draft)=>{
                draft.mode = EchossMode.StampCard
                draft.stampCardResp = result
                let cpnList = result.custCpnList.map((item:any)=>{
                    
                    let datetime = parseInt(item.expDate)
                    let year = datetime /10000
                    let month = (datetime % 1000) / 100 -1
                    let day = datetime / 100
                    item.expiredDate =  new Date(year, month, day)
                    return item
                })

                draft.couponList = cpnList
                draft.StampCard.SCARD_FKEY = SCARD_FKEY
                draft.StampCard.sid = sid
                draft.StampCard.allCount = parseInt(result.allCount)
                draft.StampCard.allStampAccuCnt = parseInt(result.allStampAccuCnt)
                draft.StampCard.LimitStampCard = config.StampCard![sid].LimitStampCard
            }))
        }, store.actions.onError
        )
        
    }

const setStampCardCoupon = (
    store:Store<EchossState, EchossAction>, 
    payload:CouponType) => {
        let config = store.state.config!
        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: set_aprvData(config.OTP_DIV_CD_USE_COUPON, store.state.customerID, store.state.StampCard.SCARD_FKEY, payload.issueNo),
            funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_CARD,
            isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.USE_COUPON,
            cntYn 		: "N",
        }, ()=>{
            // set to stampcard state initiate  user close coupon popup
            store.setState ( produce (store.state, (draft)=>{
                draft.stampInProgress = false
                draft.coupon.status = '2'
            }))
            console.log('done otp');
        }, store.actions.onError
        )

        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampCardCoupon
            draft.coupon = payload
        }))
    }

const setStampCardCancel = (
    store:Store<EchossState, EchossAction>, 
    payload:any) => {
        let config = store.state.config!
        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: set_aprvData(config.OTP_DIV_CODE_CANCEL_SAVING_UP, store.state.customerID, store.state.StampCard.SCARD_FKEY),
            funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_CARD,
            isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.CANCEL_COLLECT,
            cntYn 		: "N",
        }, ()=>{
            // 
            console.log('done otp');
        }, store.actions.onError
        )

        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampCardCancel
        }))
    }

const welcomeUpdateUserInfo= async(
    store:Store<EchossState, EchossAction>,
    payload:any )=>{
        let sid = store.state.StampCard.sid!
        let info : userInfo = {
            key : store.state.config!.API_KEY,
            id : store.state.customerID!
        }
        
        if (payload.phone) info.ctn = payload.phone
        if (payload.name) info.name = payload.name
        if (payload.email) info.email = payload.email
        if (payload.dob) info = { ...info, birth: payload.dob.replace(/-/g,'') }
        console.log(info)
        // let data = produce( store.state, (draft) =>{
        //     draft.userInfo!.equip = "equip"
        //     if (payload.phone) draft.userInfo!.ctn = payload.phone
        //     if (payload.name) draft.userInfo!.name = payload.name
        //     if (payload.email) draft.userInfo!.email = payload.email
        //     if (payload.email) draft.userInfo!.birth = payload.dob
        // })
        // data.ctn = payload.phone
        // const api = commonApi(store.state.config!.PC_API_URL)        
        try {
            // let res = await api.updateInfo( info!, store.state.config!.StampCard![sid].ClientId! )

            let data = produce( store.state, (draft) =>{
                draft.userInfo!.equip = payload.equip || "equip"
                if (payload.phone) draft.userInfo!.ctn = payload.phone
                if (payload.name) draft.userInfo!.name = payload.name
                if (payload.email) draft.userInfo!.email = payload.email
                // if (payload.gen) draft.userInfo!.gen = payload.gen
                
            })
            // data.ctn = payload.phone
                    
            echoss.User.changeInfo ( { ...data.userInfo , key : store.state.config!.API_KEY } , ( )=>{
            // echoss.User.changeInfo ( data.userInfo , ( )=>{
                store.actions.getUserInfo(store.state.customerID!)
                // if feature enabled
                // store.actions.setRegisInfo('updated')
            store.actions.setRegisInfo(JSON.stringify(payload))
            }, store.actions.onError)

            // // Only issue coupon when the update is successful
            // store.actions.getUserInfo(store.state.customerID!)
            // // if feature enabled
            // store.actions.setRegisInfo(JSON.stringify(payload))
        }catch (error){
            console.log(error)
            store.actions.onError(error, error)
        }

        // echoss.User.changeInfo ( { ...data.userInfo , key : store.state.config!.API_KEY } , ( )=>{
        // echoss.User.changeInfo ( data.userInfo , ( )=>{
            // store.actions.getUserInfo(store.state.customerID!)
            // if feature enabled
            // store.actions.setRegisInfo(JSON.stringify(payload))
        // }, store.actions.onError)
    }
    
const setRegisInfo= (
    store:Store<EchossState, EchossAction>,
    payload: string )=>{
        let data = {
            id : store.state.customerID,
            funckey : store.state.StampCard.SCARD_FKEY,
            data : payload
        }
        echoss.Stampcard.registPersonalInfo ( data , ( )=>{
            store.actions.getStampCardInfo()
            // swal("welcome coupon issued")
        }, store.actions.onError)
    }

const setStampHistory= (
    store:Store<EchossState, EchossAction> )=>{
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampHistory
        }))
    }
    
export default {
    actions : {
        stampAccumulated,
        setStampCard,
        setStampCardCancel,
        setStampCardCoupon,
        setStampHistory,
        setRegisInfo,
        getStampCardInfo,
        updateStampCardStamp,
        welcomeUpdateUserInfo
    }
}