import swal from "sweetalert"

export const getBaseURI = ()=>{
    if (document.baseURI.search("localhost") !== -1 ) return ""
    let baseuri = document.baseURI.split("/")
    return baseuri[baseuri.length-2]
}

export const swal_img = ( imgurl:string| undefined , msg: string|undefined, default_msg :string )=>{
    if (imgurl){
        swal({
            buttons : [false],
            className : 'swal-img' ,
            icon : imgurl,
        })
    }else {
        swal({
            text : msg || default_msg
        })
    }
}