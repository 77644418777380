import swal from "sweetalert";

declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB :any;
    }
}

export function initFB(FB_APP_ID:string, version:string, onSuccess: (response:any)=>void  ) {
    if (document.getElementById('facebook-jssdk')) {
        // this.sdkLoaded();
        return;
    }
    loadSdkAsynchronously ('en_US' )    

    window.fbAsyncInit = function () {
        window.FB.init({
            appId: FB_APP_ID,
            xfbml: true,
            cookie: true,
            version:`v${version}` 
        });

        window.FB.getLoginStatus(function (response:any) {
            console.log(response);
            if (response.status === 'connected') {
                window.FB.api('/me?fields=id,name,email', function (response : any) {
                    console.log(response);
                    if (!response || response.error) {
                        swal("FB connection failed.");
                    } else {
                        let osType = 'A';
                        if (!navigator.userAgent.match(/Android/i)) {
                            osType = 'I';
                        }
                        onSuccess (response)
                        // 고객 정보 저장
                    }
               });
            } else {
                console.log(document.cookie);
                window.location.href = `https://www.facebook.com/dialog/oauth?client_id=${FB_APP_ID}&redirect_uri=${document.baseURI}&scope=public_profile,email`
                // location.href = "https://www.facebook.com/dialog/oauth?client_id=" + FB_APP_ID + "&redirect_uri="&scope=public_profile,email";
            }
        }, true);
    };
}

function loadSdkAsynchronously(language:string) {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs:any = element;
      let js:any = element;
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = `https://connect.facebook.net/${language}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }