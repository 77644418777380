import { Store } from 'use-global-hook'
import produce from "immer";

import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData, actions } from './echoss';

declare var echoss: any;

export type RallyInfo = {
    key : string 
    name: string
    rallyImgUrl: string 
    allCount: string
    benefit: string
    bgImgUrl: string
    accuOnImgUrl: string
    accuOffImgUrl:string
    excptMerchant:string
    expDate: string,
    expTime: string,
    rallyCpnCount : string
    rallyCpnList: {
      order:string
      code:string
      name:string
      description: string
      imgUrl: string
      imgDetailUrl:string
      onImgUrl: string
      offImgUrl:string
    }
    rallyOdrCpnCount:string
    rallyOdrCpnList: any[]
    rallyOdrMcntCount:string
    rallyOdrMcntList:{
        order:string
        code:string
        name:string
        onImgUrl:string
    }[],
    existYn:string
    accuCount:string
    accuList :{
        accuNo:string
        order:string
        accuOnImgUrl:string
        accuDate:string
        accuTime:string
        accuMcntId:string
        accuMcntNm:string
    }[],
    custCpnList:{
        issueNo:string
        code:string
        name:string
        description:string
        imgUrl:string
        imgDetailUrl:string
        no:string
        issueDate:string
        issueTime:string
        expDate:string
        expTime:string
        status:string
        cancelDate:string
        cancelTime:string
        useDate:string
        useTime:string
        cpnNo:string
    }[]
}

export type RallyState = {
    info? : RallyInfo
}

export interface RallyAction {
    setRallyMode : (payload: string) => void
    setRallyCouponRedeem : (payload:string) =>void
    updateRally : ( payload: any) =>void
    onRallyStamped : (payload: any) =>void
    onRallyCouponRedeemed : (payload: any) =>void
}
const setRallyMode = (
    store:Store<EchossState, EchossAction>, payload: any ) => {
        store.actions.rally.updateRally({
            CUST_ID : store.state.customerID,
            FUNC_KEY : store.state.config!.Rally![0].FUNC_KEY
        })
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampRally
        }))
        
    }
const updateRally = (
    store:Store<EchossState, EchossAction>, payload: any ) => {
        echoss.Stamprally.addAndGetDetailinfo({
            id 		: payload.CUST_ID,

            funckey : payload.FUNC_KEY
        }, (result:any)=>{
            console.log(result)
            store.setState(produce( store.state, draft =>{
                draft.Rally.info = result
            }))
        },
        store.actions.onError,
        )
    }


const setRallyCouponRedeem = (
    store:Store<EchossState, EchossAction>) => {
        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: `${store.state.pointCard.cardKey},${store.state.config!.POINTCARD_KEY}`,
            funcCd      : "FPT",
            isuDivCd	: echoss.Icon.OTP_ISSUE_TYPE.COMMON_ETC,
            cntYn 		: "N",
        }, async (result:any)=>{
            // let data:;
            // let data = await pointAPI.getOTP(store.state.pointCard.cardKey!)
            // console.log(data)
            // set to stampcard state initiate  user close coupon popup
            store.setState ( produce (store.state, (draft)=>{
                draft.stampInProgress = false
                // draft.pointCard.stampResult = data 
            }))
        }, (errCode:any, errMsg:any)=>{ 
            // store.setState ( produce (store.state, (draft)=>{
            //     draft.pointCard.stampResult = undefined 
            // }))
            store.actions.onError(errCode, errMsg) }
        )
        // console.log('stamp mode')
        // store.actions.updatePointCard()
        store.setState(produce(store.state, (draft)=>{
            draft.mode = EchossMode.StampPointCard
        }))
        // store.actions.updatePointCard()
    }

const onRallyStamped = async(
    store:Store<EchossState, EchossAction>, 
    payload: any ) => {
        const config = store.state.config!
        echoss.Stamprally.collect({
            id          : payload.USER_ID,
			funckey 	: payload.FUNC_KEY,
            stampParams : payload.stampParams
        }, function (result : any) {
            // stamping(false);
            // usedCoupon();
            store.setState(produce(store.state, (draft)=>{
                draft.pointCard.stampResult = result
            }))
        }, 
            store.actions.onError
        );
        
    }   


const onRallyCouponRedeemed = async(
    store:Store<EchossState, EchossAction>, 
    payload: any ) => {
        const config = store.state.config!
        echoss.Stamprally.useCoupon({
            id          : payload.USER_ID,
			type 		: "1",
            issueNo: payload.ISSUE_NO,
            stampParams: payload.stampParams
        }, function (result : any) {
            // stamping(false);
            // usedCoupon();
            store.setState(produce(store.state, (draft)=>{
                draft.pointCard.stampResult = result
            }))
        }, 
            store.actions.onError
        );
        
    }   


export default {
    actions : {
        updateRally,
        onRallyStamped,
        onRallyCouponRedeemed,
        setRallyCouponRedeem,
        setRallyMode,
    }
}