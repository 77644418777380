import React from 'react';

type LoadingProps = {
 }

// let Sound = RSound.Sound
export const Loading : React.FC<LoadingProps> = ()=>{
    
    return (
        <div className="dimmed flex_center" style={{
        }}>
            <img src='images/loading.gif' alt='Loading...' className='loading' style={{height: "4rem", width:"4rem"}}/>
        </div>
    )
}

export const Loading_Component : React.FC<LoadingProps> = ()=>{
    
    return (
        <div className="flex_center" style={{
            height:"100%"
        }}>
            <img src='images/loading.gif' alt='Loading...' className='' style={{height: "4rem", width:"4rem"}}/>
        </div>
    )
}