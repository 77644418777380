import { Store } from 'use-global-hook'
import produce from "immer";

import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData } from './echoss';

declare var echoss: any;

export interface RouletteState {
    address : string,
    allCount : string,
    benefit : string,
    bgImgUrl : string,
    bnftIsuYn : string,
    category : string,
    city : string,
    cpnIsuYn : string,
    custCpnList : CouponType[],
    description : string,
    imgUrl : string,
    key : string,
    latitude : string,
    longitude : string,
    name : string,
    prizeCount : string,
    roulCpnList : RCouponType[],
    roulImgUrl : string,
    roulName : string,
    roulRsltList : RouletteResultType[],
    tel : string
}

export type RouletteResultType = {
    authDivCd : string,
    code : string,
    merchantId : string,
    merchantName : string,
    prizeYn : string,
    roulDate : string,
    roulNo : string,
    roulTime : string,
    stampNo : string
}

export type RCouponType = {
    code : string,
    description : string,
    divCd : string,
    imgDetailUrl : string,
    imgUrl : string,
    name : string,
    prizeRate : string
}

export interface RouletteAction {
    setRoulette: (payload: string) => void
    setRouletteCoupon:(payload:CouponType)=>void,
    issueRouletteCoupon:()=>void
    // getRouletteInfo: (payload: string) => void
    // setCoupon: (payload: CouponType) => void
}

const setRoulette= (
    store:Store<EchossState, EchossAction>, 
    payload:string) => {
        let rid = parseInt(payload) || 0
        console.log(rid)
        let config = store.state.config!

        let FUNC_KEY = config!.FUNC_KEY![rid]
        // Handle no FUNC_KEY
        let Roulette = { FUNC_KEY}
        if (config!.Roulette ) { 
            Roulette = {...Roulette , ...config!.Roulette![rid]}   
        }
        echoss.Icon.showIcon();
        echoss.Stamproulette.addAndGetDetailinfo({
            id: store.state.customerID,
            funckey: FUNC_KEY,
            type: config.OTP_DIV_CD_SAVE_UP
        }, (result: any) => {
            console.log(result)

            store.setState( produce(store.state, (draft)=>{
                draft.mode = EchossMode.Roulette
                let cpnList = result.custCpnList.map((item:any)=>{
                    
                    let datetime = parseInt(item.expDate)
                    let year = datetime /10000
                    let month = (datetime % 1000) / 100 -1
                    let day = datetime / 100
                    item.expiredDate =  new Date(year, month, day)
                    return item
                })
                draft.couponList = cpnList

                draft.rouletteState = result

                draft.Roulette = Roulette 
            }))
        },
            store.actions.onError
        )

        echoss.Icon.setOtpData({
            aprvData 	: set_aprvData(config.OTP_DIV_CD_SAVE_UP, store.state.customerID , FUNC_KEY),
            funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_ROULETTE,
            isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.COLLECT,
            cntYn 		: "N",
        }, ()=>{            
            // Trigger stamping in progress/ roulette start
            store.setState(produce(store.state, (draft)=>{
                draft.stampInProgress = true
            }))
            // stamp in progress is set to false by issue roulette action
            store.actions.issueRouletteCoupon()
            console.log('done set opt')
        }, store.actions.onError
        )
    }

const setRouletteCoupon = (
    store:Store<EchossState, EchossAction>, 
    payload:CouponType) => {
        let config = store.state.config!
        let FUNC_KEY = store.state.Roulette.FUNC_KEY

        echoss.Icon.showIcon();
        echoss.Icon.setOtpData({
            aprvData 	: set_aprvData(config.OTP_DIV_CD_USE_COUPON, store.state.customerID , FUNC_KEY, payload.issueNo),
            funcCd 		: echoss.Icon.OTP_FUNC_CODE.STAMP_ROULETTE,
            isuDivCd 	: echoss.Icon.OTP_ISSUE_TYPE.USE_COUPON,
            cntYn 		: "N",
        }, ()=>{
            store.setState ( produce (store.state, (draft)=>{
                draft.stampInProgress = false
                draft.coupon.status = '2'
            }))
            // success OTP proceed with succes stamp            
        }, store.actions.onError
        )

        store.setState(produce(store.state, (draft)=>{
            draft.coupon = payload
            draft.mode = EchossMode.RouletteCoupon
        }))

    }

const issueRouletteCoupon = (
    store:Store<EchossState, EchossAction> ) => {
        echoss.Stamproulette.issueCoupon({
            id: store.state.customerID,
            funckey: store.state.Roulette.FUNC_KEY,
        },
            function (result: any) {
                let data = produce( store.state, (draft)=>{
                    draft.stampInProgress = false
                    draft.newCoupon  = result.cpnIsuYn === 'Y' 
                    draft.couponList = result.custCpnList
                    draft.rouletteState = result
                })
                
                setTimeout(()=> {
                    console.log(data)
                    store.setState(data)
                }, 4500 )
                
            },
            store.actions.onError
        );
    }

// const getRouletteInfo= (
//     store:Store<EchossState, EchossAction>, 
//     payload:any) => {
//         let config = store.state.config!
//         echoss.Stamproulette.addAndGetDetailinfo({
//             id: store.state.customerID ,
//             funckey: config.FUNC_KEY,
//             type: config.OTP_DIV_CD_SAVE_UP
//         },
//             store.actions.setCoupon,
//             store.actions.onError
//         );
//     }
    

// const setCoupon = (
//     store:Store<EchossState, EchossAction>, 
//     payload:CouponType ) => {
//     // const newcoupon = store.state.coupon + payload
    
//     store.setState(produce(store.state, (draft)=>{
//         draft.coupon = payload
//     }))
// }


export default {
    actions : {
        setRoulette,
        setRouletteCoupon,
        issueRouletteCoupon
        // getRouletteInfo
    }
}