import { Store } from 'use-global-hook'
import produce from "immer";

import { EchossState, EchossAction, EchossMode, CouponType, set_aprvData, actions } from './echoss';
import { mcouponApi } from 'services/mcoupon';
import Axios from 'axios';

declare var echoss: any;

export const mcouponAPI = (process.env.NODE_ENV ==='production') ? mcouponApi('https://pm-echoss.zvolution.com.my') : mcouponApi('https://dev-pm-echoss.zvolution.com.my')

export type TransactionErrorType = {
    id : string,
    txid : string | null,
    amount ? : string,
    error_date? : string,
    items : string,
}

export type ApiState = {
    TransactionErrorList? : TransactionErrorType []
}

export interface ApiCall {
    getTransactionError : ( )=>void
}
const zurl = "https://api.zvolution.com.my"
const getTransactionError = async(
    store:Store<EchossState, EchossAction>, 
    payload: any ) => {

        try {
            let res = await Axios.post(`${zurl}/echoss/txerror`,{
                    cid : store.state.customerID,
                    channel : store.state.config!.VOUCHER_CHANNEL
            })
            console.log(res)
            store.setState(produce( store.state, (draft)=>{
                draft.ApiState.TransactionErrorList = res.data 
            }))
        }catch(error){
            console.log(error)
        }
    }   


export interface ApiCallAction {
    getTransactionError : ()=>void
}
    
export default {
    actions : {
        getTransactionError
    }
}
